import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate, ABanner, Go } from 'shared/components/common'
import { Container, Row, Col } from 'reactstrap'

type TerminosProps = PageProps
interface TerminosState {
  mounted: boolean
}
class Terminos extends Component<TerminosProps, TerminosState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state

    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <React.Fragment>
            <ABanner title="Términos y condiciones" titleClassName="big-white" titleSize={12} />

            <Container className="pad">
              <Row>
                <Col sm={12} className="text-justify">
                  <ul>
                    <li>
                      <h4 className="privacy-title">Compra de boletos</h4>
                    </li>
                    <ol>
                      <li>Se deberá presentar con su credencial o identificación oficial al realizar la compra de su boleto.</li>
                      <li>
                        El boleto es válido únicamente para la fecha y hora indicada en el mismo, favor de mantenerlo hasta el final de su
                        recorrido ya que le da el derecho al seguro de viaje.
                      </li>
                      <li>
                        Para boletos abiertos no se ocupa definir la fecha del viaje si no hasta que usted decida viajar, considerando que
                        el boleto abierto tiene vigencia de 1 año el cual queda sujeto a disponibilidad y espacio y descuentos autorizados
                        por autobús.
                      </li>
                      <li>Para boletos abiertos es necesario hacer el check in en mostrador antes de abordar.</li>
                      <li>
                        Boletos comprados a través de internet con tarjeta de crédito, tarjeta de débito o cualquier otro medio electrónico,
                        es necesario presentarse en la taquilla antes de 2 horas de salida de su viaje presentando el número de
                        confirmación, tarjeta con la que se realizó la compra y una identificación que le acredite como dueño de esta misma.
                      </li>
                      <li>
                        Para compras realizadas a través de comercio electrónico deberá presentar copia impresa del boleto electrónico donde
                        esta misma debe ser clara y legible para poder abordar el autobús, a su vez deberá presentar su identificación
                        oficial (INE, Pasaporte, Licencia de Manejo, Cédula Profesional) ya que en caso de no presentar ambos documentos no
                        se permitirá el abordaje al autobús.
                      </li>
                      <li>
                        Los boletos emitidos por cualquier tipo de compra no son comprobantes fiscales, por lo que en caso de requerir
                        factura deberá notificar en la taquilla o descargar a través de la&nbsp;
                        <Go to="/solicitarfactura">plataforma web</Go>. Solo se podrán facturar boletos que se encuentren dentro del lapso
                        que permita la empresa.
                      </li>
                    </ol>
                    <li>
                      <h4 className="privacy-title">Restricciones</h4>
                    </li>
                    <ol>
                      <li>
                        El boleto es válido únicamente para la fecha y hora indicada en el mismo, en caso de no abordar el autobús se
                        cancelará su boleto de manera automática sin responsabilidad para la empresa. Si el pasajero tuviera un retraso de
                        hasta 60 min posteriores a la salida del autobús designado para su viaje, tendrá la oportunidad de viajar en el
                        siguiente autobús otorgándole un 50% del valor del boleto, o bien tendrá la opción de transferir el 50% del valor de
                        su boleto adquirido a monedero electrónico el cual podrá aplicarlo en futuras compras.
                      </li>
                      <li>No se permitirá que ningún menor de 12 años viaje solo, deberá estar acompañado en todo momento de un adulto.</li>
                      <li>
                        El pasajero tendrá derecho a transportar 1 maleta con un peso de hasta 35kg, o bien 2 maletas que en suma el peso no
                        sea mayor a 50kg, por lo que en caso de ser mayor en cualquiera de las dos situaciones, se cobrará un monto
                        excedente.
                      </li>
                      <li>
                        Para el caso de medidas, no se deberá exceder de .15m3 o 160cm lineales, de lo contrario se cobrará excedente.
                      </li>
                      <li>
                        No se permitirá viajar a ningún pasajero en estado de ebriedad o bajo efectos de cualquier tipo de droga, o bien
                        pasajeros que muestren actitudes que puedan alterar el orden y poner en riesgo a los demás usuarios.
                      </li>
                      <li>
                        Si algún pasajero muestra actitudes que alteren el orden y seguridad de los usuarios en el trayecto del viaje, se le
                        solicitara su descenso en la próxima estación autorizada.
                      </li>
                      <li>
                        No se permitirá viajar con armas de fuego o armas punzo cortantes, drogas o cualquier otra mercancía prohibida por
                        la ley.
                      </li>
                      <li>Los boletos adquiridos con algún tipo de descuento no son transferibles.</li>
                      <li>
                        Cualquier controversia derivada de la prestación del servicio, las partes se sujetan a la normatividad en materia de
                        autotransporte federal de pasaje.
                      </li>
                    </ol>
                    <li>
                      <h4 className="privacy-title">Documentación</h4>
                    </li>
                    <ol>
                      <li>La documentación del equipaje será como máximo 30 minutos antes de la salida programada.</li>
                      <li>
                        No se aceptarán maletas que en su interior contengan mercancías catalogadas como No transportables, las cuales se
                        establecen en los lineamientos para la transportación de mercancías.
                      </li>
                      <li>
                        Solo se permitirá llevar en el interior del salón de pasajeros equipaje de mano el cual deberá viajar en los
                        compartimientos habilitados y bajo su responsabilidad.
                      </li>
                      <li>
                        En caso de que se observen daños en la maleta al momento de su documentación, se deberá reportar al cliente para que
                        firme de conformidad.
                      </li>
                      <li>
                        Para el caso de clientes que viajen con mercancía catalogada como carga refrigerada se toman las siguientes
                        consideraciones:
                      </li>
                      <ul>
                        <li>La mercancía deberá viajar siempre acompañada de su dueño.</li>
                        <li>Deberá cumplir con las medidas establecidas para este fin.</li>
                        <li>La mercancía viaja a responsabilidad del cliente.</li>
                        <li>La empresa no se hace responsable por mercancía olvidada o dañada (se depurará en el momento).</li>
                        <li>La carga refrigerada no es asegurable.</li>
                        <li>Deberá viajar pagada. (el flete)</li>
                      </ul>
                    </ol>
                    <li>
                      <h4 className="privacy-title">Daños y extravíos</h4>
                    </li>
                    <ol>
                      <li>
                        Si el pasajero no documentó ni declaró el contenido de su equipaje, la empresa no se hará responsable por daños y
                        extravíos.
                      </li>
                      <li>
                        Para el caso de extravíos de equipaje la empresa tendrá un margen para la búsqueda de 72 horas después de sucedido
                        el evento.
                      </li>
                      <li>El tiempo máximo para presentar una reclamación por extravio es 72 horas después de sucedido el evento.</li>
                      <li>
                        La empresa no responderá por perdidas o daños a maletas si el cliente no cuenta con su talón correspondiente.{' '}
                      </li>
                      <li>Las maletas o bultos que lleve el pasajero en el interior del salón son de su absoluta responsabilidad.</li>
                      <li>
                        Una vez comprobado el extravío del equipaje y este sea imputable a la empresa, se pagará al afectado la cantidad
                        máxima de 15 VSM vigente por equipaje.{' '}
                      </li>
                    </ol>
                    <li>
                      <h4 className="privacy-title">Abordaje</h4>
                    </li>
                    <ol>
                      <li>El pasajero se deberá presentar en la puerta del andén correspondiente, 45 min antes de su salida.</li>
                      <li>
                        Antes de abordar deberán presentar identificación y el boleto que ampara su viaje en la hora y fecha señalada., en
                        caso de ser cliente con descuentos especiales (ESTUDIANTE, MAESTRO, INAPAM, PCD) deberán presentar su credencial
                        vigente.
                      </li>
                      <li>
                        Para viajes internacionales se deberá presentar como mínimo 2 horas antes de la salida de su viaje y deberá
                        presentar su pasaporte y visa vigentes.
                      </li>
                      <li>
                        El pasajero es el único responsable de sus bienes durante los procesos de revisiones que realice cualquier
                        autoridad, así como también revisiones aduanales y líneas fronterizas.{' '}
                      </li>
                    </ol>
                    <li>
                      <h4 className="privacy-title">Cambios y cancelaciones</h4>
                    </li>
                    <ol>
                      <li>
                        No habrá cancelaciones, reembolsos y/o devoluciones de cualquier tipo de boletos cuando este se haya excedido del
                        tiempo estipulado para realizar estos procesos.
                      </li>
                      <li>
                        La cancelación solo se podrá realizar en los tiempos estipulados (máximo 5 horas antes de la salida) y generarán un
                        15% de cobro extra por concepto de cancelación la cual se verá reflejado al realizar el rembolso correspondiente en
                        el monedero electrónico y/o cupones. (No habrá devoluciones de efectivo)
                      </li>
                      <li>
                        Para cualquier cambio de itinerario solo se podrá realizar en la taquilla antes del día y hora de salida del boleto
                        original (máximo 3 hora antes), y estará sujeto a disponibilidad de espacios de la nueva ruta. Adicional deberá
                        presentar identificación oficial y boleto. Se cobrará la diferencia si el nuevo destino al que viajara es más lejano
                        y tipo de servicio (directo, escala), si es un destino más cercano será el mismo cobro que el boleto original.
                      </li>
                      <li>
                        Para cambio o actualización de datos de la persona que viaja deberá realizarse máximo 2 horas antes de la salida,
                        esto no implica ningún costo adicional.
                      </li>
                    </ol>
                    <li>
                      <h4 className="privacy-title">Descuentos</h4>
                    </li>
                    <ul>
                      <li>Descuentos INAPAM</li>
                      <ol>
                        <li>
                          Se ofrece un descuento del 50% sobre el valor total de su boleto, este tipo de asientos está sujeto a
                          disponibilidad y por periodos correspondientes.
                        </li>
                      </ol>
                      <li>Descuentos a menores</li>
                      <ol>
                        <li>De 0 a 3 años no pagan boleto (no tiene derecho a asiento), viajan en el asiento del adulto.</li>
                      </ol>
                      <li>Descuentos a estudiantes.</li>
                      <ol>
                        <li>
                          El descuento será del 50% y se aplicará conforme al periodo vacacional indicado en el calendario escolar emitido
                          por la SEP. Cuando los estudiantes disfruten su periodo vacacional distinto al señalado deberán presentar
                          constancia original expedida por la institución educativa correspondiente y su credencial vigente.
                        </li>
                        <li>
                          Se otorgarán 5 asientos por autobús con descuentos para estudiantes y deberán mostrar su credencial vigente al
                          adquirirlo.
                        </li>
                        <li>
                          En caso de no presentar los documentos la empresa estará en su derecho de cobrar el monto del boleto completo
                          (100%)
                        </li>
                        <li>La credencial de estudiante debe estar legible (datos y fotografía)</li>
                      </ol>
                      <li>Descuentos maestros</li>
                      <ol>
                        <li>
                          El descuento será del 25% y se aplicará conforme al periodo vacacional indicado en el calendario escolar emitido
                          por la SEP. Cuando los maestros disfruten su periodo vacacional distinto al señalado deberán presentar constancia
                          original expedida por la institución educativa correspondiente y su credencial vigente.
                        </li>
                        <li>
                          Se otorgarán 5 asientos por autobús con descuentos para maestros y deberán mostrar su credencial vigente al
                          adquirirlo.
                        </li>
                        <li>
                          En caso de no presentar los documentos la empresa estará en su derecho de cobrar el monto del boleto completo
                          (100%)
                        </li>
                        <li>La credencial de maestro debe estar legible (datos y fotografía)</li>
                      </ol>
                      <li>Descuentos personas con capacidades diferentes</li>
                      <ol>
                        <li>
                          El descuento es del 50%, solo se otorgará presentando credencial expedida por el DIF vigente que los acredite como
                          tal, de no presentarlo en la compra del boleto se cobrará el 100% del mismo.
                        </li>
                      </ol>
                    </ul>
                    <li>
                      <h4 className="privacy-title">Formas de pago</h4>
                    </li>
                    Los servicios ofrecidos por Autotransportes y Carga All Abordo S.A.P.I. de C.V., podrán ser pagados en su totalidad con
                    uno o más de los siguientes métodos de pago:
                    <ol type="a">
                      <li>Pago en efectivo</li>
                      <li>Tarjetas bancarias (VISA, MasterCard o American Express)</li>
                      <li>Puntos de cliente ABORDO</li>
                      <li>PayPal</li>
                      <li>
                        Reservación post-pago. (Pago en central, establecimientos comerciales y/o bancos con los que se tenga convenio)
                      </li>
                    </ol>
                  </ul>
                  <ul>
                    <li>
                      <h4 className="privacy-title">Términos y condiciones Monedero Electrónico</h4>
                    </li>
                    Recompensas y beneficios aplicables para usuarios de la aplicación Abordo Móvil que utilicen el Monedero Electrónico
                    Abordo.
                    <ol>
                      <li>
                        Recompensas para el Referente:
                        <ul>
                          <li>
                            1.1 El usuario que invita a unirse al programa recibirá un 10% de dinero en Monedero Electrónico Abordo por la
                            primera compra que un referido realice a través de la aplicación Abordo Móvil.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Recompensas para el Referido:
                        <ul>
                          <li>
                            2.1 El usuario invitado recibirá un 10% de descuento en su primera compra al descargar y activar la aplicación
                            Abordo Móvil. Además, acumulará un porcentaje en Monedero Electrónico Abordo.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Uso del Monedero Electrónico Abordo:
                        <ul>
                          <li>
                            3.1 Los usuarios que acumulen dinero en su Monedero Electrónico Abordo podrán utilizarlo en futuras compras
                            realizadas fuera de la temporada vacacional y días festivos.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Condiciones Generales:
                        <ul>
                          <li>
                            4.1 Para ser elegible como referente o referido, ambos usuarios deben tener la aplicación Abordo Móvil instalada
                            y activa.
                          </li>
                          <li>
                            4.2 La recompensa del 10% en Monedero Electrónico Abordo se aplicará automáticamente en la cuenta del referente
                            después de que el referido haya realizado su primera compra a través de la aplicación.
                          </li>
                          <li>
                            4.3 El referido debe descargar y activar la aplicación Abordo Móvil para recibir el 10% de descuento en su
                            primera compra, además de acumular dinero en su Monedero Electrónico Abordo.
                          </li>
                          <li>
                            4.4 El dinero acumulado en el Monedero Electrónico Abordo puede ser utilizado en compras futuras realizadas
                            fuera de la temporada vacacional, en días festivos o feriados.
                          </li>
                        </ul>
                      </li>
                      <li>Las recompensas no son transferibles ni canjeables por efectivo.</li>
                      <li>
                        Abordo se reserva el derecho de modificar o finalizar el programa de recompensas en cualquier momento sin previo
                        aviso.
                      </li>
                      <li>Los usuarios son responsables de revisar y cumplir con los términos y condiciones.</li>
                      <li>El programa de recompensas por compra estará vigente hasta el 30 de noviembre del 2023.</li>
                    </ol>
                  </ul>
                  <ul>
                    <li>
                      <h4 className="privacy-title">Términos y condiciones Paquetería / Mensajería</h4>
                    </li>
                    Contrato de prestación de servicio de paquetería y mensajería que celebran por su parte PTX PAQUETERIA SA DE CV, que en
                    los sucesivo será denominado “El transportista” y para el usuario del servicio se le denominara “El remitente” cuyos
                    datos se encuentran manifestados en el anverso de esta carta porte.
                    <ol>
                      <li>
                        El transportista prestara al remitente el servicio de transportación de mercancía, sujeto a las condiciones
                        establecidas en la presente carta porte, reservándose el derecho de subcontratar a terceros cuando sea necesario con
                        el fin de cumplir con el presente documento.
                      </li>
                      <li>
                        Cada carta porte será considerada como un solo servicio, pero cada servicio podrá contener uno o más paquetes /
                        documentos.
                      </li>
                      <li>
                        Se garantiza por parte del remitente que el envío que se realiza en esta carta porte es legalmente aceptable y se
                        cuenta con la documentación correspondiente. En caso de presentar algún problema con las autoridades competentes a
                        razón de este envío, el remitente y destinatario serán los responsables de solventar cualquier gasto y situación
                        legal que este pudiera ocasionar.
                      </li>

                      <li>El transportista ofrecerá el servicio de transporte apegado a las siguientes restricciones:</li>
                      <ul>
                        <li>
                          4.1 Las medidas máximas permitidas en la transportación de mercancía no deberán de excederse de (50kg, .15m3 o
                          160cm totales) en caso de ser mayor en cualquier de las dos situaciones (peso y medida), se cobrará un monto
                          adicional por el excedente.
                        </li>
                        <li>
                          4.2 El valor máximo declarado por envío podrá ser de $100,000, acreditado de manera formal por su factura
                          correspondiente.
                        </li>
                        <li>
                          4.3 No se transportará ninguna mercancía prohibida por la ley o bien que pueda presentar algún riesgo para los
                          empleados, adicional a esto que pueda causar algún daño al autobús o a otras mercancías o maletas. De igual forma
                          no se transportará ninguna mercancía catalogada como No transportable por parte del transportista.
                        </li>
                      </ul>

                      <li>
                        En la entrega Ocurre es obligatorio que el cliente remitente informe en todo momento al destinatario del arribo de
                        esta mercancía, adicional a esto se facilite por parte del transportista una página para revisar el seguimiento y
                        status de los envíos.
                      </li>
                      <li>
                        El transportista se reserva el derecho de inspeccionar el envío en cualquier momento, así mismo permitir alas
                        autoridades competentes llevar a cabo las revisiones que consideren adecuadas.
                      </li>
                      <li>
                        Se acuerda por parte de los involucrados que los envíos rechazados por el destinatario o bien que no se logre
                        concretar su entrega por causas NO imputables al transportista, serán retenidos en la central correspondiente
                        esperando información por parte del remitente de como proceder, el tiempo máximo de resguardo en terminal serán de
                        10 días. Por lo anterior el remitente será el responsable por el pago de todos los cargos, incluyendo de almacenaje
                        y costos de los fletes que esto genere.
                      </li>
                      <li>
                        En caso de no poder concretar la entrega de la mercancía y no se solicite por parte del remitente la devolución o
                        reexpedición a otro destino, esta será enviada a una bodega concentradora la cual si la mercancía no es recuperada
                        en los próximos 3 meses el destinatario y remitente autorizan la venta publica, depuración o donación de esta.
                      </li>
                      <li>
                        La recuperación de la mercancía en concentradora generara un costo de almacenaje mas todos los fletes y servicios
                        acumulados hasta su recuperación. La cantidad que resulte pagar deberá ser liquidada por deposito a una cuenta
                        bancaria que se le indicara en su momento, posteriormente se procede con el envío.
                      </li>
                      <li>
                        El transportista será responsable del envío desde el momento que lo recibe hasta que se entrega al destino, excepto
                        en los siguientes casos:
                        <ol type="I">
                          <li>Por vicios propios de los bienes o productos, o por embalaje inadecuado.</li>
                          <li>Cuando la carga por su propia naturaleza sufra deterioro o daño total o parcial.</li>
                          <li>
                            Cuando los bienes se transporten a petición escrita del remitente en vehículos descubiertos, siempre que por la
                            naturaleza de aquéllos debiera transportarse en vehículos cerrados o cubiertos.
                          </li>
                          <li>
                            Falsas declaraciones o instrucciones del cargador, del consignatario o destinatario de los bienes o del titular
                            de la carta de porte.
                          </li>
                          <li>
                            Cuando el usuario del servicio no declare el valor de la mercancía, la responsabilidad quedará limitada a la
                            cantidad equivalente a 15 días de salario mínimo general vigente o la unidad de medida que corresponda o en el
                            futuro la que lo sustituya por tonelada o la parte proporcional que corresponda tratándose de embarques de menor
                            peso.
                          </li>
                        </ol>
                      </li>
                      <li>
                        El transportista reconoce como responsable del seguimiento en caso de perdida o daño de mercancía únicamente a la
                        persona física o moral usuaria del servicio proporcionado (remitente).
                      </li>
                      <li>
                        El remitente podrá adquirir una cobertura superior a la establecida por la ley mediante una Compañía aseguradora
                        legalmente autorizada por el transportista, la cual estará sujeta a una declaración por escrito y pago de servicio
                        contra riesgos, misma que será plasmada en el anverso de esta carta porte. En este supuesto el remitente deberá
                        pagar al transportista los cargos adicionales correspondientes a la prima del referido seguro. La suma asegurada no
                        podrá excederse de $100,000 por envío.
                      </li>
                      <li>
                        En el caso de que el Remitente adquiera el seguro opcional distinto al que le ofrece el transportista, éste no será
                        responsable del cumplimiento de la póliza de seguro respectiva. En caso de no contratar ningún seguro y este llegará
                        a presentar algún detalle, el mismo será sometido a lo establecido por la reglamentación de la ley de caminos y
                        puentes de autotransporte federal, Art.66 apartado V.
                      </li>
                      <li>
                        Para el caso de daños o perdidas, los documentos y acuses de recibo no tienen valor comercial, por tal motivo en
                        caso de que se presente lo anterior se deberá por parte del transportista otorgar un envío de cortesía al cliente
                        amparando las mismas condiciones del envío original. Por ningún motivo se aplicará el reembolso de efectivo.
                      </li>
                      <li>
                        Para el caso de daños o extravíos de paquetes cuyo valor fue declarado en el anverso de esta carta porte, el
                        transportista responderá por el costo de reposición del paquete, en el entendido que dicha reposición nunca será
                        mayor a $100,000 por envío y será pagado siempre y cuando se respeten los siguientes puntos.
                        <ul>
                          <li>
                            15.1. El destinatario debe hacer constar por escrito en la carta porte o acuse de recibo la pérdida o daño de la
                            mercancía y así tener la evidencia para presentar la reclamación correspondiente.
                          </li>
                          <li>
                            15.2. Que el valor del paquete haya sido declarado debidamente por parte del remitente en la carta porte, y que
                            este valor sea el de reposición de la mercancía (monto pagado por la mercancía al momento de la compra)
                          </li>
                          <li>15.3. Que no sea mercancía prohibida.</li>
                          <li>15.4. Que el remitente presente y compruebe mediante factura que el valor declarado es correcto.</li>
                          <li>15.5. Que el paquete conserve su embalaje original.</li>
                        </ul>
                      </li>
                      <li>
                        El remitente será responsable de entregar embalado su envío, si no cuenta con embalaje adecuado podrá adquirir cajas
                        de cartón de nuestra empresa, mismas que estarán a la venta pública, sin embargo, será responsabilidad del cliente
                        empacar su mercancía al igual que asumir cualquier responsabilidad que se pudiera generar por la caja de cartón, en
                        el entendido que estas no exentan a la mercancía de presentar algún daño, ni brindan una protección total a su
                        envío.
                      </li>
                      <li>
                        El transportista se obliga a llevar acabo un tratamiento adecuado de datos personales con motivo de la prestación de
                        los servicios que se mencionan en el presente contrato, en los términos y condiciones establecidos en el aviso y
                        políticas de Privacidad de Abordo, así como de conformidad con la Ley Federal de Protección de Datos Personales en
                        Posesión de Los Particulares.
                      </li>
                    </ol>
                  </ul>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        </AAnimate>
      </Layout>
    )
  }
}

export default Terminos
